import React, { useEffect, useState, useContext } from 'react';
import axios from '../axios'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import Footer from './footer'
import Home from './Home'
import Categoria from './categoria'
import CategoriaDepartamento from './categoria/CategoriaDepartamento'
import Producto from './producto'
import Carrito from './carrito'
import Direccion from './compra/direccion'
import Login from './Login/Login'
import Recuperar from './Recuperar/Recupear';
import Registro from './Registro/Registro';
import Perfil from './perfil/Perfil';
import Cupones from './Cupones/Cupones';
import ResultSearch from './ResultSearch/ResultSearch';
import Envio from './compra/envio';
import Pago from './compra/pago';
import Finalizar from './compra/finalizar';
import Pedidos from './Pedidos/Pedidos';
import DetallePedidos from './DetallePedidos/DetallePedidos';
import { DataContext } from '../context/DataContext'
import NuevoPass from './NuevoPass/NuevoPass';
import Somos from './Politicas/Somos/Somos';
import Aviso from './Politicas/Aviso/Aviso';
import FAQ from './Politicas/FAQ/FAQ';
import Glosario from './Politicas/Glosario/Glosario';
import TrabajoForm from './Politicas/TrabajoForm/TrabajoForm';
import PagosEnvios from './Politicas/PagosEnvios/PagosEnvios';
import PoliticasCompras from './Politicas/PoliticasCompras/PoliticasCompras';
import Search from './Search/Search';
import Loader from './Loader';
import Footer2 from './Footer2/Footer2';
import Departament from './departaments';
import Nav from './nav';
import Proximamente from './Proximamente/Proximamente';
import Departamentos from './categoria/departamentos/Departamentos';
import Error404 from './Errors/404';

const ScrollToTop = () => {

	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

const Dashboard = () => {

	const [loader, setLoader] = useState(true)
	const { setUser, config } = useContext(DataContext)

	useEffect(()=>{
		if(config.production_mode)
		axios.get('/api/user').then(r=>{
			setLoader(false)
			setUser(r.data)
		}).catch(r=>{
			setLoader(false)
		})

		if(config.production_mode===0)setLoader(false)

		
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[config])

	if(loader) return (
		<Loader />
	)
	if(!config.production_mode) return (<Proximamente />)


	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Route path='/proximamente' exact> <Proximamente></Proximamente> </Route>
				{/**
				  *  home
				  */}
				<Route path='/' exact> <Home /> <Footer/> </Route>
				<Route path='/deps' exact> <Nav/> <Departament/> <Footer/> </Route>
				<Route path='/cat/:categoria_id' exact><Categoria /> <Footer/> </Route>
				<Route path='/departamento/:departamento_id' exact><CategoriaDepartamento /> <Footer/> </Route>
				<Route path='/deps/:departamento_id' exact><Departamentos/> <Footer/> </Route>
				<Route path='/product/:codigo' exact><Producto /> <Footer/> </Route>
				<Route path='/product/:categoria_id/:codigo' exact><Producto /> <Footer/> </Route>
				<Route path='/search/:key' exact><ResultSearch/> <Footer/> </Route>
				<Route path='/search' exact><Search/> <Footer/> </Route>
				{/**
				  *  Auth
				  */}
				<Route path='/registro' exact><Registro /> <Footer2/> </Route>
				<Route path='/login' exact><Login /> <Footer2/> </Route>
				<Route path='/recuperar' exact><Recuperar /> <Footer2/> </Route>
				<Route path='/recuperar/:token' exact><NuevoPass /> <Footer2/> </Route>
				{/**
				  *  User
				  */}
				<Route path='/miperfil'><Perfil /> <Footer/> </Route>
				<Route path='/miscupones' exact><Cupones /> <Footer/> </Route>
				{/**
				  *  Carrito
				  */}
				<Route path='/pedidos' exact><Pedidos /> <Footer/> </Route>
				<Route path='/micarrito' exact><Carrito /> <Footer/> </Route>
				<Route path='/micarrito/direccion' exact><Direccion /> <Footer/> </Route>
				<Route path='/micarrito/envio' exact><Envio /> <Footer/> </Route>
				<Route path='/micarrito/pago' exact><Pago /> <Footer/> </Route>
				<Route path='/micarrito/finaliza' exact><Finalizar /> <Footer/> </Route>
				<Route path='/pedido/:pedido_id' exact><DetallePedidos /> <Footer/> </Route>
				{/**
				  *  Politicas
				  */}
				<Route path='/somos' exact><Somos/> <Footer2/> </Route>
				<Route path='/aviso' exact><Aviso/> <Footer2/> </Route>
				<Route path='/faq' exact><FAQ/> <Footer2/> </Route>
				<Route path='/glosario' exact><Glosario/> <Footer2/> </Route>
				<Route path='/bolsa_trabajo' exact> <TrabajoForm />  <Footer2/> </Route>
				<Route path='/pagos_envios' exact> <PagosEnvios />  <Footer2/> </Route>
				<Route path='/politicas_compras' exact> <PoliticasCompras />  <Footer2/> </Route>
				{/**
				  *  Errors
				  */}
				<Route path='/404' exact><Error404 /> <Footer/> </Route>

			</Switch>
		</Router>
	)
}

export default Dashboard
